<template>
    <FilterableDataTable :headers="headers"
                         hide-default-header
                         hide-default-footer
                         :loading="loading"
                         :single-expand="false"
                         :items="Agendas"
                         disable-pagination
                         @item-expanded="expandItem"
                         @click:row="rowClick"
                         item-key="DocumentId"
                         show-expand
                         :dense="false"
                         class="elevation-1"
                         ref="dataTable">
        <template v-slot:item.DocumentDate="{ item }">
            {{DatePretty(item.DocumentDate)}}
        </template>
        <template v-slot:item.NoMeeting="{ item }">
            <v-alert v-if="item.NoMeeting" dense text type="info" class="ma-0">No Meeting</v-alert>
        </template>
        <template v-slot:item.DocumentId="props">
            <div class="text-end">
                <v-btn @click.stop v-if="!props.item.NoMeeting && props.item.DocumentDate >= new Date() && (props.item.InterimYearCommitteeId == 472 || props.item.InterimYearCommitteeId == 486 || props.item.SessionCommitteeId == 1240 || props.item.SessionCommitteeId == 1244 || props.item.SessionCommitteeId == 1227)"
                       tile
                       outlined
                       rel="noopener"
                       :to="'/Testify/' + props.item.DocumentId">
                    Register to Testify
                </v-btn>
                <v-btn @click.stop="" tile outlined rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + props.item.DocumentId + '.pdf'">View PDF</v-btn>
                <v-btn @click="showHtml(props, $event)" tile outlined>View Html</v-btn>
                <v-btn @click="showDocuments(props, $event)" tile outlined>View Documents</v-btn>
                <v-btn @click.stop="" v-if="LoggedIn" tile outlined rel="noopener" :href="$MyLRCUrl.value + 'Main/Document/' + props.item.DocumentId">View On MyLRC+</v-btn>
            </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
                <v-progress-linear indeterminate v-if="item.loading"></v-progress-linear>
                <div v-if="item.ShowHtml" v-html="item.HTML" style="position: relative;" class="pa-2"></div>
                <div v-if="item.ShowDocuments">
                    <Documents InDialog :Documents="item.MeetingDocuments"></Documents>
                </div>
            </td>
        </template>
        <template v-slot:footer="{}">
            <v-btn v-if="Agendas && Agendas.length > 1 && combindURLHTML" tile outlined block rel="noopener" :href="combindURLHTML">View Combined HTML</v-btn>
            <v-btn v-if="Agendas && Agendas.length > 1 && combindURL" tile outlined block rel="noopener" :href="combindURL">View Combined PDF</v-btn>
        </template>
    </FilterableDataTable>
</template>

<script>
    import moment from 'moment'
    import { DateTime } from 'luxon'
    const Documents = () => import("@/Documents/Documents.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    export default {
        name: 'Agendas',
        props: ['CommitteeId'],
        components: {
            Documents, FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loading = true;
                if (this.currentRoute.includes('/Interim')) {
                    fetch('/api/InterimCommittees/Documents/' + this.CommitteeId + "?Type=3&Type=5", {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json().then(r => {
                                r = r.map(x => {
                                    x.loading = false;
                                    x.ShowHtml = false;
                                    x.ShowDocuments = false;
                                    x.MeetingDocuments = [];

                                    return x;
                                });
                                this.Agendas = EncodeDate(r);
                            });
                        }
                        else {
                            throw new Error(res.statusText);
                        }
                    }).catch(error => {
                        throw new Error(error);
                    });

                }
                else {
                    fetch('/api/SessionCommittees/Documents/' + this.CommitteeId + "?Type=3&Type=5", {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json().then(r => {
                                r = r.map(x => {
                                    x.loading = false;
                                    x.ShowHtml = false;
                                    x.ShowDocuments = false;
                                    x.MeetingDocuments = [];

                                    return x;
                                });

                                this.Agendas = EncodeDate(r);
                            });
                        }
                        else {
                            throw new Error(res.statusText);
                        }

                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            openDocumentHTML(DocumentId) {
                return fetch('/api/Documents/' + DocumentId + '.html', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.text();
                    }
                    else {
                        return "<p>No HTML Available<p>";
                    }

                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            GetMeetingDocuments(DocumentId) {
                return fetch('/api/Documents/Meeting/' + DocumentId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return [];
                    }

                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            async expandItem(item) {
                if (!item.item.ShowHtml && !item.item.ShowDocuments) {
                    item.item.ShowHtml = true;
                    item.item.ShowDocuments = false;
                }
                else if (item.item.ShowHtml) {
                    if (item.item.HTML == null) {
                        item.item.loading = true;
                        item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                        item.item.loading = false;
                    }
                }
                else {
                    if (item.item.MeetingDocuments.length == 0) {
                        item.item.loading = true;
                        item.item.MeetingDocuments = await this.GetMeetingDocuments(item.item.DocumentId);
                        item.item.loading = false;
                    }
                }
            },
            async showDocuments(item, e) {
                item.item.ShowHtml = false;
                item.item.ShowDocuments = true;

                if (item.item.MeetingDocuments.length == 0) {
                    item.item.loading = true;
                    item.item.MeetingDocuments = await this.GetMeetingDocuments(item.item.DocumentId);
                    item.item.loading = false;
                }
            },
            async showHtml(item, e) {
                item.item.ShowHtml = true;
                item.item.ShowDocuments = false;

                if (item.item.HTML == null) {
                    item.item.loading = true;
                    item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                    item.item.loading = false;
                }
            },
            rowClick(any, item) {
                if (!item.isExpanded) {
                    if (!item.item.ShowHtml && !item.item.ShowDocuments) {
                        item.item.ShowHtml = true;
                        item.item.ShowDocuments = false;
                    }

                    this.expandItem(item);
                }
                else {
                    item.item.ShowHtml = false;
                    item.item.ShowDocuments = false;
                }

                item.expand(!item.isExpanded);
            },
        },
        data: function () {
            return {
                loading: true,
                loadingHTML: true,
                headers: [
                    { text: 'Date', value: 'DocumentDate' },
                    { text: '', value: 'NoMeeting', filterable: false },
                    { text: '', value: 'DocumentId', filterable: false },
                    { text: '', value: 'data-table-expand', filterable: false, width: '5ch' }],
                YearCommittee: {
                    Session: {
                        Year: '',
                        SessionId: null,
                    },
                    Committee: {
                        MeetingTime: '',
                        Room: {
                            Room: '',
                        },
                        Days: '',
                    },
                },
                Agendas: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            }
        },
        computed: {

            CommitteeType: function () {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                } else {
                    return "Session";
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
            LoggedIn: function () {
                return this.$LoggedIn;
            },
            combindURL() {
                if (this.Agendas) {
                    let PublicMinDocs = this.Agendas.filter(x => x.DocumentId && x.DocumentId > 0).sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                    if (PublicMinDocs.length < 2) {
                        return null;
                    }
                    var str = "";
                    PublicMinDocs.forEach(i => {
                        if (i.DocumentId) {
                            str += "&Ids=" + i.DocumentId;
                        }
                    });
                    str = str.substring(1, str.length);
                    return this.$MyLRCUrl.value + 'api/Documents/Combined.pdf?' + str;
                }
                return null;
            },
            combindURLHTML() {
                if (this.Agendas) {
                    let PublicMinDocs = this.Agendas.filter(x => x.DocumentId && x.DocumentId > 0).sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                    if (PublicMinDocs.length < 2) {
                        return null;
                    }
                    var str = "";
                    PublicMinDocs.forEach(i => {
                        if (i.DocumentId) {
                            str += "&Ids=" + i.DocumentId;
                        }
                    });
                    str = str.substring(1, str.length);
                    return this.$MyLRCUrl.value + 'api/Documents/Combined.html?' + str;
                }
                return null;
            },
        }
    };
</script>
<style></style>