<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <BudgetAgencyList v-if="selectedSessionId" :SessionId="selectedSessionId"></BudgetAgencyList>
        </v-col>
        <v-col md="10" sm="12">
            <v-card>
                <v-card-title>
                    <v-autocomplete v-model="selectedSessionId"
                                    style="max-width:100px"
                                    :items="Sessions"
                                    item-value="Session.SessionId"
                                    item-text="Session.Year"></v-autocomplete> Hearing Documents
                </v-card-title>
                <v-card-text>


                    <h2 v-if="DocumentHearing != null">{{DocumentHearing.Description}}</h2>
                    <div v-else>
                        <v-alert type="info">Please select an agency in the menu</v-alert>
                    </div>
                    <div v-if="DocumentHearing">
                        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                        <v-list>
                            <v-list-item v-for="i in Documents" :key="i.DocumentId">
                                <v-list-item-content>
                                    <a class="text-uppercase" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Resource/' +i.DocumentId + '.pdf'">{{ i.Title || i.Description || i.Filename.replace('.pdf','') }}</a>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                        <v-alert v-if="Documents.length == 0 && !loading && DocumentHearing != null" dense
                                 text
                                 type="info">No documents available.</v-alert>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const BudgetAgencyList = () => import("@/Menus/BudgetAgencyList.vue");

    export default {
        name: 'BudgetHearingDocuments',
        props: ['DocumentHearingId'],
        components: {
            BudgetAgencyList
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        },
        methods: {
            LoadSessionsOnce() {
                fetch('/api/Documents/DocumentType/UniqueSessions?Type=53', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    var res = EncodeDatetimes(response);
                    res = res.sort((a, b) => (a.Session.Year > b.Session.Year) ? -1 : 1);
                    this.Sessions = res;

                }).catch(error => {
                    throw new Error(error);
                });

            },
            LoadData() {
                //this.selectedSessionId = this.SessionId;
                this.loading = true;

                fetch('/api/Documents/DocumentTypeHearings?SessionIds=' + this.selectedSessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.AllDocuments = EncodeDatetimes(response);
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });

                fetch('/api/DocumentHearings', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {

                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.DocumentHearings = EncodeDatetimes(response);

                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                DocumentHearings: [],
                selectedSessionId: 70, //TODO: FIX
                AllDocuments: [
                    //{
                    //    Name: 'Document # 1',
                    //    url: '/Documents/#id',
                    //},
                ],
                loading: true,
                Sessions: [],
            }
        },
        mounted: function () {
            this.LoadSessionsOnce();
            this.LoadData();
        },
        watch: {
            selectedSessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () { return 'Budget Hearing Documents' },
            Description: function () {
                return this.Title;
            },
            Documents: function () {
                // this.AllDocuments = this.AllDocuments.filter(x => x.DocumentHearings != null);
                var res = this.AllDocuments.filter(x => x.DocumentHearingId == this.DocumentHearingId).map(x => {
                    var a = '000' + x.Title;
                    var b = a.indexOf('.');
                    if (b > -1) {
                        a = a.slice(0, b);
                    }
                    x.Sort = parseInt(a);

                    return x;
                });
                res.sort((a, b) => {
                    if ((a.Sort > b.Sort)) {
                        return 1;
                    } else if ((a.Sort < b.Sort)) {
                        return -1;
                    }
                    else {
                        return (a.Title > b.Title) ? 1 : -1;
                    }


                });

                return res;
            },
            DocumentHearing: function () {
                if (this.DocumentHearingId) {
                    return this.DocumentHearings.find(x => x.DocumentHearingId == this.DocumentHearingId);
                }
                return null;
            },
        }
    };
</script>
<style>
</style>