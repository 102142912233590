var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FilterableDataTable',{ref:"dataTable",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-header":"","hide-default-footer":"","loading":_vm.loading,"single-expand":false,"items":_vm.Agendas,"disable-pagination":"","item-key":"DocumentId","show-expand":"","dense":false},on:{"item-expanded":_vm.expandItem,"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.DocumentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DatePretty(item.DocumentDate))+" ")]}},{key:"item.NoMeeting",fn:function(ref){
var item = ref.item;
return [(item.NoMeeting)?_c('v-alert',{staticClass:"ma-0",attrs:{"dense":"","text":"","type":"info"}},[_vm._v("No Meeting")]):_vm._e()]}},{key:"item.DocumentId",fn:function(props){return [_c('div',{staticClass:"text-end"},[(!props.item.NoMeeting && props.item.DocumentDate >= new Date() && (props.item.InterimYearCommitteeId == 472 || props.item.InterimYearCommitteeId == 486 || props.item.SessionCommitteeId == 1240 || props.item.SessionCommitteeId == 1244 || props.item.SessionCommitteeId == 1227))?_c('v-btn',{attrs:{"tile":"","outlined":"","rel":"noopener","to":'/Testify/' + props.item.DocumentId},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Register to Testify ")]):_vm._e(),_c('v-btn',{attrs:{"tile":"","outlined":"","rel":"noopener","href":_vm.$MyLRCUrl.value + 'api/Documents/' + props.item.DocumentId + '.pdf'},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("View PDF")]),_c('v-btn',{attrs:{"tile":"","outlined":""},on:{"click":function($event){return _vm.showHtml(props, $event)}}},[_vm._v("View Html")]),_c('v-btn',{attrs:{"tile":"","outlined":""},on:{"click":function($event){return _vm.showDocuments(props, $event)}}},[_vm._v("View Documents")]),(_vm.LoggedIn)?_c('v-btn',{attrs:{"tile":"","outlined":"","rel":"noopener","href":_vm.$MyLRCUrl.value + 'Main/Document/' + props.item.DocumentId},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("View On MyLRC+")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[(item.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(item.ShowHtml)?_c('div',{staticClass:"pa-2",staticStyle:{"position":"relative"},domProps:{"innerHTML":_vm._s(item.HTML)}}):_vm._e(),(item.ShowDocuments)?_c('div',[_c('Documents',{attrs:{"InDialog":"","Documents":item.MeetingDocuments}})],1):_vm._e()],1)]}},{key:"footer",fn:function(ref){return [(_vm.Agendas && _vm.Agendas.length > 1 && _vm.combindURLHTML)?_c('v-btn',{attrs:{"tile":"","outlined":"","block":"","rel":"noopener","href":_vm.combindURLHTML}},[_vm._v("View Combined HTML")]):_vm._e(),(_vm.Agendas && _vm.Agendas.length > 1 && _vm.combindURL)?_c('v-btn',{attrs:{"tile":"","outlined":"","block":"","rel":"noopener","href":_vm.combindURL}},[_vm._v("View Combined PDF")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }