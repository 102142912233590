<template>
    <v-container>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-card class="mb-4" v-if="!loading">
            <v-card-title>Register to Testify</v-card-title>
            <v-card-text>
                <strong>Title:</strong> {{ meetingInfo.Title }}<br />
                <strong>Date:</strong> {{ DatePretty(meetingInfo.DocumentDate) }} <span v-if="relativeTime">({{relativeTime}})</span> <span v-if="meetingInfo.DocumentDateComment">{{meetingInfo.DocumentDateComment}}</span><br />
                <strong>Room:</strong> {{ meetingInfo.Room }}<br />
            </v-card-text>
        </v-card>
        <v-alert v-if="meetingInfo.DocumentDate && IsMeetingTimePast && !IsFourHoursPast" type="warning">This meeting is currently ongoing.</v-alert>
        <v-alert v-if="meetingInfo.DocumentDate && IsFourHoursPast" type="error">Registration is no longer available for this meeting.</v-alert>
        <v-form v-if="!loading && !success && !IsFourHoursPast" ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
                <v-col cols="6">
                    <v-text-field v-model.trim="testifierPending.name"
                                  label="Name"
                                  name="Name"
                                  required
                                  autocapitalize="words"
                                  hint="The full name of the person planning on testifying at the meeting. Example - 'John Doe'"
                                  :rules="[v => !!v || 'Name is required', v => v.trim().length > 0 || 'Name cannot be empty or just whitespace']"
                        @blur="loadRepresentingOptions"
                        autocomplete="name">
                    </v-text-field>
                    <v-alert v-if="showNameAlert"
                             dense
                             type="warning"
                             prominent>
                        Please provide both a first and last name (e.g., 'John Doe').
                    </v-alert>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model.trim="testifierPending.RegistrationNumber"
                                  label="Lobbyist Registration Number (Optional)"
                                  name="LobbyistRegistrationNumber"
                                  hint="If you are a registered lobbyist the Registered Lobbyist Number provided by the South Dakota Secretary of State. https://sosenterprise.sd.gov/businessservices/lobbyist/default.aspx"
                                  autocomplete="lobbyistregistrationnumber"></v-text-field>
                </v-col>
            </v-row>
            <div>How would you like to join the meeting?</div>
            <v-radio-group v-model="testifierPending.InPerson" :rules="[v => v !== null || 'Requested Meeting Attendance Type is required.']" required row>
                <v-radio label="In Person" :value="true"></v-radio>
                <v-radio label="Remotely" :value="false"></v-radio>
            </v-radio-group>
            <v-text-field v-model.trim="testifierPending.email"
                          :label="testifierPending.InPerson == false ? 'Email' : 'Email (Optional)'"
                          name="Email"
                          type="email"
                          @blur="loadRepresentingOptions"
                          :rules="testifierPending.InPerson == false ? [v=>!!v || 'Email is required', v => /.+@.+/.test(v) || 'Email must be valid'] : [v => !v || /.+@.+/.test(v) || 'Email must be valid']"
                          autocomplete="email">
            </v-text-field>
            <v-combobox v-model.trim="testifierPending.representing"
                        label="Representing"
                        name="Representing"
                        autocapitalize="words"
                        :items="representingOptions"
                        item-text="Employer"
                        item-value="Employer"
                        :return-object="false"
                        @change="onRepresentingChange"
                        hint="The entity you are employed by, representing, or 'Self' if representing yourself."
                        autocomplete="organization"
                        required
                        :rules="[v => !!v || 'Who you are representing is required']"
                        clearable></v-combobox>
            <v-alert v-if="SetRegistrationNumber" type="info">We've set your Lobbyist Registration number for you based on your selection.</v-alert>
            <!--<v-alert type="warning" v-if="testifierPending.email && testifierPending.email.toLowerCase().endsWith('@state.sd.us') && testifierPending.representing == 'Self'">If you are representing a state entity enter the name above.</v-alert>-->
            <!--<v-alert type="info" v-if="UnregisteredLobbyist" variant="outlined" outlined dense>
                <p>We couldn't find an active lobbyist registration for you under this entity.</p>
                <p>
                    You can still register for this meeting, but might need to register as a lobbyist with the
                    <a href="https://sosenterprise.sd.gov/BusinessServices/Lobbyist/LobbyistSearch.aspx" target="_blank">
                        Secretary of State's office
                    </a>
                </p>
            </v-alert>-->
            <v-text-field v-model.trim="testifierPending.city"
                          label="City"
                          name="City"
                          autocapitalize="words"
                          :rules="[v => !!v || 'City is required']"
                          hint="The city you are from"
                          autocomplete="address-level2"></v-text-field>
            <v-autocomplete v-model="testifierPending.state"
                            hint="The state you are from."
                            :items="States"
                            item-text="name"
                            item-value="abbreviation"
                            autocomplete="address-level1"
                            :rules="[v=> !!v || 'State is required', v => States.some(state => state.abbreviation === v) || 'Please select a valid state']"
                            required
                            clearable
                            label="State" />
            <div>(Note: We will seek approval from the Chair for any remote requests.)</div>
            <v-divider class="my-4"></v-divider>
            <v-card class="mb-4" v-for="(item, index) in agendaItems" :key="index">
                <v-card-title v-if="item.Title && !CustomAgenda" v-html="item.Title" class="pl-3 pt-0" style="display: block;"></v-card-title>
                <v-text-field density="compact" v-if="CustomAgenda" v-model.trim="item.Title" label="Topic you wish you testify on." required></v-text-field>
                <v-card-text>
                    <v-radio-group v-model="item.testimony" row>
                        <v-radio label="For" value="For"></v-radio>
                        <v-radio label="Against" value="Against"></v-radio>
                        <v-radio label="Presenting" value="Presenting"></v-radio>
                        <v-radio label="Other" value="Other"></v-radio>
                        <v-radio label="Not Applicable" value="N/A"></v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>
            <v-btn :disabled="!valid || loading || saving" color="success" @click="submitForm">Submit Registration</v-btn>
        </v-form>
        <v-alert type="error" v-if="RequiredTestimony">Please respond For, Against, or Presenting to at least one option and then submit your registration.</v-alert>
        <v-alert v-if="success" type="success">
            Registration submitted successfully!
            <v-progress-linear v-model="progressBarWidth"></v-progress-linear>
        </v-alert>
        <v-alert v-if="error" type="error" dismissible>
            Failed to submit registration. {{ errorMessage }}
        </v-alert>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import { DateTime } from 'luxon';
    export default {
        name: 'Testify',
        props: ['AgendaId'],
        components: {
        },
        methods: {
            LuxonNow(add) {
                if (add == undefined || add == null || add == 0)
                    return DateTime.now();
                return DateTime.now().plus(add);
            },
            LuxonDateify(jsDate) {
                return DateTime.fromJSDate(jsDate);
            },
            async LoadData() {
                this.LoadedTime = new Date();
                this.testifierPending.DocumentId = this.AgendaId;
                this.loading = true;
                try {
                    if (this.AgendaId == undefined || this.AgendaId < 1) {
                        this.loading = false;
                        this.$router.go(-1);
                    }
                    const response = await fetch('/api/Testify/' + this.AgendaId, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });

                    this.loading = false;
                    if (response.ok) {
                        const data = await response.json();
                        this.meetingInfo = {
                            Title: data.Title,
                            DocumentDate: data.DocumentDate,
                            LuxonDate: DateTime.fromISO(data.DocumentDate),
                            DocumentDateComment: data.DocumentDateComment,
                            Room: data.Room
                        };
                        if (data.Name) {
                            this.testifierPending.name = data.Name;
                        }
                        if (data.Email) {
                            this.testifierPending.email = data.Email;
                        }
                        this.agendaItems = data.AgendaItems.map(agenda => ({
                            Title: agenda.Title,
                            testimony: null,
                            ActionlogId: agenda.ActionLogId,
                            BillId: agenda.BillId,
                        }));
                        this.CustomAgenda = false;
                        if (this.agendaItems.length === 0) {
                            this.CustomAgenda = true;
                            this.agendaItems = [
                                { Title: '', testimony: null },
                            ];
                        }
                        this.OneMinuteProcess();
                    } else {
                        throw new Error(response.statusText);
                    }
                } catch (error) {
                    this.loading = false;
                    console.error('Error loading data:', error);
                }
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            async loadRepresentingOptions() {
                if (!this.testifierPending.name) {
                    return;
                }
                this.validateName();
                try {
                    this.loadingEntities = true;
                    const response = await fetch(`/api/testify/matching-entities?name=${this.testifierPending.name}`);
                    if (response.ok) {
                        const data = await response.json();
                        let selfoption = [{ Employer: 'Self', RegistrationNumber: null }];
                        this.representingOptions = data.concat(selfoption);
                        if (this.representingOptions && this.representingOptions.length > 1) {
                            if (!this.testifierPending.representing) {
                                this.testifierPending.representing = this.representingOptions[0].Employer;
                            }
                        }
                    } else {
                        this.representingOptions = [{ Employer: 'Self', RegistrationNumber: null }];
                    }
                } catch (error) {
                    console.error('Error fetching representing options:', error);
                    this.representingOptions = [];
                } finally {
                    this.loadingEntities = false;
                }
            },
            onRepresentingChange(selected) {
                this.SetRegistrationNumber = false;
                if (this.representingOptions && this.representingOptions.length > 0) {
                    //console.log(this.testifierPending.representing);
                    const selectedEntity = this.representingOptions.find(option => option.Employer === selected);
                    if (selectedEntity && selectedEntity.RegistrationNumber && !this.testifierPending.RegistrationNumber) {
                        this.testifierPending.RegistrationNumber = selectedEntity.RegistrationNumber;
                        this.SetRegistrationNumber = true;
                    }
                }
                //If we aren't representing ourself and the entity we are representing is not in the list, display an alert about unregistered lobbyist.
                if (this.testifierPending.representing && this.testifierPending.representing != 'Self') {
                    if (!this.representingOptions.some(option => option.Employer === this.testifierPending.representing)) {
                        this.UnregisteredLobbyist = true;
                    }
                    else {
                        this.UnregisteredLobbyist = false;
                    }
                }
                else {
                    this.UnregisteredLobbyist = false;
                }
            },
            async submitForm() {
                this.RequiredTestimony = false;
                //Check if the user has selected For, Against, or Presenting for at least one item.
                if (this.agendaItems.some(item => ['For', 'Against', 'Presenting'].includes(item.testimony))) {
                    this.RequiredTestimony = false;
                } else {
                    this.RequiredTestimony = true;
                }
                if (this.RequiredTestimony) {
                    return;
                }
                if (this.$refs.form.validate()) {
                    this.testifierPending.testimonyData = JSON.stringify(this.agendaItems);
                    try {
                        this.saving = true;
                        const response = await fetch('/api/Testify/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(this.testifierPending)
                        });
                        this.saving = false;
                        if (response.ok) {
                            this.success = true;
                            this.error = false;
                            this.showNameAlert = false;
                            // Reset the form
                            this.testifierPending = {
                                DocumentId: this.AgendaId,
                                name: '',
                                city: '',
                                state: 'SD',
                                representing: '',
                                email: '',
                                testimonyData: '',
                                InPerson: true,
                                RegistrationNumber: '',
                            };
                            this.agendaItems.forEach(item => (item.testimony = ''));
                            this.startCountdown();
                        } else {
                            this.error = true;
                            this.success = false;
                            //Display Bad Request Text Error
                            const data = await response.text();
                            this.errorMessage = data;
                            console.error('Error submitting form:', data);
                        }
                    } catch (err) {
                        this.error = true;
                        this.success = false;
                        this.saving = false;
                        reportError(err);
                    }
                }
            },
            OneMinuteProcess() {
                this.IsMeetingTimePast = DateTime.now() > DateTime.fromISO(this.meetingInfo.DocumentDate);
                this.relativeTime = DateTime.fromISO(this.meetingInfo.DocumentDate).toRelative();
                this.IsFourHoursPast = DateTime.now() > DateTime.fromISO(this.meetingInfo.DocumentDate).plus({ hours: 16 });
            },
            startCountdown() {
                this.countdown = 5;
                if (this.CountdownInterval) {
                    clearInterval(this.CountdownInterval);
                }
                this.CountdownInterval = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        this.success = false;
                        clearInterval(this.CountdownInterval);
                    }
                }, 1000);
            },
            validateName() {
                if (this.testifierPending && this.testifierPending.name) {
                    const nameParts = this.testifierPending.name.split(' ').filter(Boolean);
                    this.showNameAlert = nameParts.length < 2;
                }
            },
        },
        data: function () {
            return {
                UnregisteredLobbyist: false,
                RequiredTestimony: false,
                SetRegistrationNumber: false,
                countdown: 0,
                CountdownInterval: null,
                IsMeetingTimePast: false,
                IsFourHoursPast: false,
                relativeTime: null,
                errorMessage: '',
                loading: true,
                saving: false,
                Agenda: {},
                TestifyData: {},
                valid: false,
                success: false,
                error: false,
                showNameAlert: false,
                CustomAgenda: false,
                testifierPending: {
                    DocumentId: null,
                    name: '',
                    city: '',
                    state: 'SD',
                    representing: '',
                    email: '',
                    testimonyData: '',
                    InPerson: true,
                    RegistrationNumber: '',
                },
                agendaItems: [
                    { title: '', testimony: null },
                ],
                meetingInfo: {
                    Title: '',
                    DocumentDate: '',
                    DocumentDateComment: '',
                    Room: ''
                },
                States: [
                    {
                        "name": "Alabama",
                        "abbreviation": "AL"
                    },
                    {
                        "name": "Alaska",
                        "abbreviation": "AK"
                    },
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS"
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ"
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR"
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA"
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO"
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT"
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE"
                    },
                    {
                        "name": "District Of Columbia",
                        "abbreviation": "DC"
                    },
                    {
                        "name": "Federated States Of Micronesia",
                        "abbreviation": "FM"
                    },
                    {
                        "name": "Florida",
                        "abbreviation": "FL"
                    },
                    {
                        "name": "Georgia",
                        "abbreviation": "GA"
                    },
                    {
                        "name": "Guam",
                        "abbreviation": "GU"
                    },
                    {
                        "name": "Hawaii",
                        "abbreviation": "HI"
                    },
                    {
                        "name": "Idaho",
                        "abbreviation": "ID"
                    },
                    {
                        "name": "Illinois",
                        "abbreviation": "IL"
                    },
                    {
                        "name": "Indiana",
                        "abbreviation": "IN"
                    },
                    {
                        "name": "Iowa",
                        "abbreviation": "IA"
                    },
                    {
                        "name": "Kansas",
                        "abbreviation": "KS"
                    },
                    {
                        "name": "Kentucky",
                        "abbreviation": "KY"
                    },
                    {
                        "name": "Louisiana",
                        "abbreviation": "LA"
                    },
                    {
                        "name": "Maine",
                        "abbreviation": "ME"
                    },
                    {
                        "name": "Marshall Islands",
                        "abbreviation": "MH"
                    },
                    {
                        "name": "Maryland",
                        "abbreviation": "MD"
                    },
                    {
                        "name": "Massachusetts",
                        "abbreviation": "MA"
                    },
                    {
                        "name": "Michigan",
                        "abbreviation": "MI"
                    },
                    {
                        "name": "Minnesota",
                        "abbreviation": "MN"
                    },
                    {
                        "name": "Mississippi",
                        "abbreviation": "MS"
                    },
                    {
                        "name": "Missouri",
                        "abbreviation": "MO"
                    },
                    {
                        "name": "Montana",
                        "abbreviation": "MT"
                    },
                    {
                        "name": "Nebraska",
                        "abbreviation": "NE"
                    },
                    {
                        "name": "Nevada",
                        "abbreviation": "NV"
                    },
                    {
                        "name": "New Hampshire",
                        "abbreviation": "NH"
                    },
                    {
                        "name": "New Jersey",
                        "abbreviation": "NJ"
                    },
                    {
                        "name": "New Mexico",
                        "abbreviation": "NM"
                    },
                    {
                        "name": "New York",
                        "abbreviation": "NY"
                    },
                    {
                        "name": "North Carolina",
                        "abbreviation": "NC"
                    },
                    {
                        "name": "North Dakota",
                        "abbreviation": "ND"
                    },
                    {
                        "name": "Northern Mariana Islands",
                        "abbreviation": "MP"
                    },
                    {
                        "name": "Ohio",
                        "abbreviation": "OH"
                    },
                    {
                        "name": "Oklahoma",
                        "abbreviation": "OK"
                    },
                    {
                        "name": "Oregon",
                        "abbreviation": "OR"
                    },
                    {
                        "name": "Palau",
                        "abbreviation": "PW"
                    },
                    {
                        "name": "Pennsylvania",
                        "abbreviation": "PA"
                    },
                    {
                        "name": "Puerto Rico",
                        "abbreviation": "PR"
                    },
                    {
                        "name": "Rhode Island",
                        "abbreviation": "RI"
                    },
                    {
                        "name": "South Carolina",
                        "abbreviation": "SC"
                    },
                    {
                        "name": "South Dakota",
                        "abbreviation": "SD"
                    },
                    {
                        "name": "Tennessee",
                        "abbreviation": "TN"
                    },
                    {
                        "name": "Texas",
                        "abbreviation": "TX"
                    },
                    {
                        "name": "Utah",
                        "abbreviation": "UT"
                    },
                    {
                        "name": "Vermont",
                        "abbreviation": "VT"
                    },
                    {
                        "name": "Virgin Islands",
                        "abbreviation": "VI"
                    },
                    {
                        "name": "Virginia",
                        "abbreviation": "VA"
                    },
                    {
                        "name": "Washington",
                        "abbreviation": "WA"
                    },
                    {
                        "name": "West Virginia",
                        "abbreviation": "WV"
                    },
                    {
                        "name": "Wisconsin",
                        "abbreviation": "WI"
                    },
                    {
                        "name": "Wyoming",
                        "abbreviation": "WY"
                    }
                ],
                representingOptions: [{ Employer: 'Self', RegistrationNumber: null }],
                LoadedTime: new Date(),
                OneMinuteInterval: null,
            }
        },
        mounted: function () {
            this.LoadData();
            this.OneMinuteProcess();
            this.OneMinuteInterval = setInterval(this.OneMinuteProcess, 60000);
        },
        unmounted() {
            if (this.OneMinuteInterval) {
                clearInterval(this.OneMinuteInterval);
                this.OneMinuteInterval = null;
            }
            if (this.CountdownInterval) {
                clearInterval(this.CountdownInterval);
                this.CountdownInterval = null;
            }
        },
        watch: {
            AgendaId: function () {
                this.LoadData();
            }
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            LoggedIn: function () {
                return this.$LoggedIn;
            },
            progressBarWidth() {
                if (!this.countdown) {
                    return 0;
                }
                return (this.countdown / 5) * 100;
            },
        },
        metaInfo() {
            //https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
            let removeEmpty = function (obj) {
                const newObj = {};

                Object.entries(obj).forEach(([k, v]) => {
                    if (Array.isArray(v)) {
                        newObj[k] = new Array();

                        v.forEach(x => {
                            if (x === Object(x)) {
                                newObj[k].push(removeEmpty(x));
                            }
                            else if (x != null) {
                                newObj[k].push(x);
                            }
                        });
                    }
                    else if (v === Object(v)) {
                        newObj[k] = removeEmpty(v);
                    } else if (v != null) {
                        newObj[k] = obj[k];
                    }
                });
                return newObj;
            }

            let Title = 'Register for ' + this.meetingInfo.Title;

            let committee = {
                title: Title,
                meta: [
                    { vmid: 'description', name: 'description', content: Title, },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: Title },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: Title }
                ]
            };
            committee = removeEmpty(committee);

            return committee;
        }
    };
</script>
<style></style>