<template>
    <div>
        <v-card flat>
            <v-card-title v-if="Session && Session.YearString">{{Session.YearString}} Conference Committees</v-card-title>
            <v-card-text>
                <div v-html="PageText"></div>
            </v-card-text>
        </v-card>
        <hr />
        <v-card flat class="pa-0">
            <v-card-text class="px-0">
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <FilterableDataTable :headers="TableHeaders"
                                 :items="AllConferenceCommittees"
                                 hide-default-footer
                                 group-by="BillNumber"
                                 disable-pagination
                                 disable-sort
                                 :items-per-page="5"
                                 class="elevation-1">
                <template v-slot:group.header="{items, isOpen, toggle, group, groupby, remove}">
                    <td colspan="7" class="d-print-table-cell pl-2">
                        <b><router-link :to="'/Session/Bill/' + items[0].Bill.BillId">{{items[0].Bill.BillType + ' ' + items[0].Bill.BillNumberOnly}}</router-link> - {{items[0].Bill.Title}}</b>
                        <!--<v-icon v-if="isOpen" @click="toggle">{{minus}}</v-icon>
                        <v-icon v-else @click="toggle">{{plus}}</v-icon>-->
                    </td>
                </template>
                <template v-slot:item.MeetingTime="{ item }">
                    {{DatePretty(item.MeetingTime)}}
                </template>
                <template v-slot:item.Senate="{ item }">
                    <div style="display:inline" v-for="(j,i) in filterMembers(item, 'S')">
                        <router-link :to="'/Legislators/Profile/' +j.SessionMemberId">{{j.LastName }}</router-link><span v-if="j.Membership == 'Chair'"> (Chair)</span><span v-if="j.Membership == 'Co-Chair'"> (Co-Chair)</span><span v-if="i < (filterMembers(item, 'S').length-1)">, </span>
                    </div>
                </template>
                <template v-slot:item.House="{ item }">
                    <div style="display:inline" v-for="(j,i) in filterMembers(item, 'H')">
                        <router-link :to="'/Legislators/Profile/' +j.SessionMemberId">{{j.LastName }}</router-link><span v-if="j.Membership == 'Chair'"> (Chair)</span><span v-if="j.Membership == 'Co-Chair'"> (Co-Chair)</span><span v-if="i < filterMembers(item, 'H').length-1">, </span>
                    </div>
                </template>
                <template v-slot:item.Report="{ item }">
                    <span v-if="item.Report !=null">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.Report.DocumentId + '.pdf'">Report</a>
                    </span>
                </template>
                <template v-slot:item.Minutes="{ item }">
                    <span v-if="item.Minutes !=null">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.Minutes.DocumentId + '.pdf'">Minutes</a>
                    </span>
                </template>
                <template v-slot:item.CustomDisposition="{ item }">
                    <div v-if="item.Disposition.SenateAdoptionText != null">
                        <span v-if="item.Disposition.SenateAdoptionText.StatusText =='Conference Committee Report adopted'">Senate adopted report</span>
                        <span v-if="item.Disposition.SenateAdoptionText.StatusText =='Conference Committee Report adopted, no committee appointed'">Senate report adopted - No Committee Appointed</span>
                        <span v-if="item.Disposition.SenateAdoptionText.StatusText =='Conference Committee report not adopted, appoint Conference Committee'">Senate report not adopted</span>
                        <span v-if="item.Disposition.SenateAdoptionText.StatusText =='Conference Committee report not adopted, no committee appointed'">Senate report not adopted</span>
                    </div>
                    <div v-if="item.Disposition.HouseAdoptionText != null">
                        <span v-if="item.Disposition.HouseAdoptionText.StatusText =='Conference Committee Report adopted'">House adopted report</span>
                        <span v-if="item.Disposition.HouseAdoptionText.StatusText =='Conference Committee Report adopted, no committee appointed'">House report adopted - No Committee Appointed</span>
                        <span v-if="item.Disposition.HouseAdoptionText.StatusText =='Conference Committee report not adopted, appoint Conference Committee'">House report not adopted</span>
                        <span v-if="item.Disposition.HouseAdoptionText.StatusText =='Conference Committee report not adopted, no committee appointed'">House report not adopted</span>
                    </div>
                </template>
            </FilterableDataTable>
            </v-card-text>
        </v-card>

    </div>
</template>
<script>
    import moment from 'moment'
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    import { mdiPlus } from '@mdi/js';
    import { mdiMinus } from '@mdi/js';

    export default {
        name: 'ConferenceCommittees',
        props: ['SessionId'],
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loading = true;

                fetch('/api/ConferenceCommittees/Session/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    response = EncodeDatetimes(response);

                    response.forEach(x => {
                        x.forEach(y => {
                            if (y.CommitteeMembers) {
                                y.CommitteeMembers.sort((a, b) => {
                                    return (a.LastName.toUpperCase() < b.LastName.toUpperCase() ? -1 : (a.LastName > b.LastName) ? 1 : 0);
                                });

                                y.CommitteeMembers.sort((a, b) => {
                                    return (a.Membership.toUpperCase() < b.Membership.toUpperCase() ? -1 : (a.Membership > b.Membership) ? 1 : 0);
                                });
                            }
                        });

                    });


                    this.ConferenceCommittees = response;
                }).catch(error => {
                    throw new Error(error);
                });
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/D/YYYY h:mm A');
                } else {
                    return null;
                }
            },
            filterMembers: function (item, type) {
                if (item.CommitteeMembers) {
                    return item.CommitteeMembers.filter(x => x.MemberType == type);
                }
            },
        },
        data: function () {
            return {
                loading: true,
                Session: {},
                plus: mdiPlus,
                minus: mdiMinus,
                TableHeaders: [
                    { text: 'Date', value: 'MeetingTime', filterable: false, class: 'max-w-25' },
                    { text: 'Room', value: 'Room', filterable: false, class: 'max-w-8' },
                    { text: 'Senate', value: 'Senate', filterable: false, },
                    { text: 'House', value: 'House', filterable: false,  },
                    { text: 'Minutes', value: 'Minutes', filterable: false, class: 'max-w-8' },
                    { text: 'Report', value: 'Report', filterable: false, class: 'max-w-8' },
                    { text: 'Disposition', value: 'CustomDisposition', filterable: false, class: 'max-w-28' },
                ],
                PageText: 'When a bill or resolution has passed its house of origin, it is presented to the other house for its consideration. If the other house should amend the bill or resolution as passed by the house of origin, it is returned to the house of origin for approval of the changes. If the house of origin does not agree to the changes, one house or the other can request that a conference committee be established to work out an agreement. Upon the request of one house, the other house must appoint three members from its body to meet with three members of the requesting house. <br /> <br />For a bill to become law, identical versions of the bill must pass both the Senate and House of Representatives for consideration by the Governor.',
                ConferenceCommittees: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            AllConferenceCommittees: function () {
                let ccs = [];
                let now = new Date();

                now.setHours(now.getHours() - 6);

                for (let i = 0; i < this.ConferenceCommittees.length; i++) {
                    for (let j = 0; j < this.ConferenceCommittees[i].length; j++) {
                        //if (this.ConferenceCommittees[i][j].MeetingTime > now) {
                        //    cc.push(this.ConferenceCommittees[i][j]);
                        //}
                        let cc = this.ConferenceCommittees[i][j];
                        cc.BillNumber = cc.Bill.BillType + ' ' + cc.Bill.BillNumberOnly + ' ' + cc.Bill.Title;
                        ccs.push(cc);
                    }
                }
                return ccs;
            },
            Title: function () {
                let title = 'Conference Committees';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + " Conference Committees";
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style scoped>
    .max-w-8 {
        max-width: 8ch;
    }
    .max-w-15 {
        max-width: 15ch;
    }
    .max-w-25 {
        max-width: 25ch;
    }
    .max-w-28 {
        max-width: 28ch;
    }
</style>